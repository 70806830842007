module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-theme-material-ui-top-layout@5.2.0_@mui+material@5.15.15_gatsby@5.13.4/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+react@11.11.4_gatsby@5.13.4/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.4_react-dom@18.3.0_react@18.3.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JSP57566SL"],"gtagConfig":{"optimize_id":"GTM-P38X7FTR","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.4_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ec3afa118f02e81c54ecb3d155be70e0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.4_babel-eslint@10.1.0_react-dom@18.3.0_react@18.3.0_typescript@5.4.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
